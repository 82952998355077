import React, {
  FC,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "@/actions/account.actions";

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import css from "./loginForm.modules.scss";
import { AppStateType } from "@/reducers";

interface ILoginFormProps {
  setActiveLoginForm: Dispatch<SetStateAction<boolean>>;
  setActiveResetPasswordForm: Dispatch<SetStateAction<boolean>>;
}

const LoginForm: FC<ILoginFormProps> = ({
  setActiveLoginForm,
  setActiveResetPasswordForm,
}) => {
  const { error } = useSelector((state: AppStateType) => state.account);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (): void => {
    dispatch(login(username, password));
  };

  const openResetPasswordForm = (): void => {
    setActiveLoginForm(false);
    setActiveResetPasswordForm(true);
  };

  return (
    <div>
      <Form
        form={form}
        name="normal_login"
        className={`flex flex-col ${css.container}`}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
      >
        <div className="flex flex-col items-center">
          <h1 className="text-x3 mb-3">Вход</h1>
          <h2 className="text-n2 text-center text-blue-grey mb-3">С возвращением! Введите ваши данные для авторизации.</h2>
        </div>
        <Form.Item
          name="username"
          className="mb-2 pt-2"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите Логин!",
            },
            {
              type: "email",
              message: "Пожалуйста, введите корректную электронную почту!",
            },
          ]}
        >
          <Input
            style={{height: 45}}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Логин"
            prefix={<UserOutlined className="site-form-item-icon" />}
            autoComplete="username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="mb-3"
          rules={[{required: true, message: "Пожалуйста введите Пароль!"}]}
        >
          <Input.Password
            style={{height: 45}}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Пароль"
            prefix={<LockOutlined className="site-form-item-icon" />}
            autoComplete="current-password"
          />
        </Form.Item>
        <Form.Item className="mb-0">
          <div className="flex flex-col text-center">
            <Button
              type="primary"
              htmlType="submit"
              className="mb-3"
            >
              Войти
            </Button>
            <div className="flex flex-col">
              <Button
                type="text"
                className="text-electric-blue"
                onClick={openResetPasswordForm}
              >
                Не помню пароль
              </Button>
              <Button
                type="text"
                className="text-electric-blue"
                onClick={() => {}}
              >
                Зарегистрироваться
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
      {!!error && (
        <div className="text-center text-error text-n1">
          {Array.isArray(error)
            ? error.map((err, index) => {
              return <span key={index} className="block mb-2">{err.msg}</span>
            })
            : error
          }
        </div>
      )}
    </div>
  );
};

export default LoginForm;
