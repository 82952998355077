import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Form, Input, Modal } from "antd";
import css from "../loginForm/loginForm.modules.scss"
import {
  accountResetPassword,
  accountResetPasswordFailure,
} from "@/actions/account.actions";
import { AppStateType } from "@/reducers";

interface IResetPasswordFormProps {
  setActiveLoginForm: Dispatch<SetStateAction<boolean>>;
  setActiveResetPasswordForm: Dispatch<SetStateAction<boolean>>;
}

const ResetPasswordForm: FC<IResetPasswordFormProps> = ({
  setActiveLoginForm,
  setActiveResetPasswordForm,
}) => {
  const { resetPasswordStatus } = useSelector((state: AppStateType) => state.account);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [email, setEmail] = useState<string>('');
  // To disable submit button at the beginning.
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (resetPasswordStatus === 204) {
      success();
      dispatch(accountResetPasswordFailure());
      setActiveResetPasswordForm(false);
    }
  }, [resetPasswordStatus]);

  const resetPassword = () => {
    dispatch(accountResetPassword(email));
  };

  const openLoginFrom = () => {
    const params = window.location.search;

    if (params.includes('reset_token')) {
      window.location.replace('login')
    } else {
      setActiveLoginForm(true);
      setActiveResetPasswordForm(false);
    }
  }

  const success = () => {
    Modal.success({
      width: '26%',
      content: 'Ожидайте письмо со ссылкой для восстановления пароля.',
      onOk() {openLoginFrom()},
    });
  };

  return (
    <Form
      form={form}
      name="normal_login"
      className={`flex flex-col ${css.container}`}
      initialValues={{ remember: true }}
      //onFinish={resetPassword}
    >
      <Form.Item
        className="mb-3"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'Введите корректный адрес электронной почты',
          },
          {
            required: true,
            message: 'Пожалуйста, введите свой адрес электронной почты',
          },
        ]}
      >
        <Input
          placeholder="E-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate
        className="mt-1 mb-1 text-center"
      >
        {() => (
          <Button
            type={
            !form.isFieldsTouched(true) ||
            !!form.getFieldsError().filter(({ errors }) => errors.length).length
              ? "default"
              : "primary"
            }
            htmlType="submit"
            className="w-full"
            onClick={resetPassword}
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            Восстановить
          </Button>
        )}
      </Form.Item>
      <Form.Item
        shouldUpdate
        className="mb-0 text-center"
      >
        <Button
         type="text"
         onClick={openLoginFrom}
       >
        Назад
       </Button>
      </Form.Item>
    </Form>
  )}

export default ResetPasswordForm;
