import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmNewPasswordFailure,
  checkConfirmNewPasswordStatus,
  checkNewPasswordConfirmedStatus,
  newPasswordConfirmedStatusFailure,
} from "@/actions/account.actions";
import { Button, Form, Input, Spin } from "antd";
import css from "../loginForm/loginForm.modules.scss"
import { navigate as reach_navigate } from "@reach/router";

interface IConfirmNewPasswordFormProps {
  resetTokenLink: string;
  setActiveLoginForm: Dispatch<SetStateAction<boolean>>;
  setActiveConfirmNewForm: Dispatch<SetStateAction<boolean>>;
  setActiveResetPasswordForm: Dispatch<SetStateAction<boolean>>;
}

const ConfirmNewPasswordForm: FC<IConfirmNewPasswordFormProps> = ({
  resetTokenLink,
  setActiveLoginForm,
  setActiveConfirmNewForm,
  setActiveResetPasswordForm,
}) => {

  const [newPassword, setNewPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const { confirmNewPasswordStatus, newPasswordConfirmedStatus } = useSelector((state: AppStateType) => state.account);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkConfirmNewPasswordStatus(resetTokenLink));
  }, []);

  useEffect(() => {
    if (confirmNewPasswordStatus || !confirmNewPasswordStatus) {
      setLoading(false);
    }

    if (newPasswordConfirmedStatus === 204) {
      //window.location.replace('login');
      reach_navigate("/login");
      dispatch(newPasswordConfirmedStatusFailure());
    }
  }, [confirmNewPasswordStatus, newPasswordConfirmedStatus]);

  const saveNewPassword = () => {
    dispatch(checkNewPasswordConfirmedStatus(resetTokenLink, newPassword));
  }

  const cancelReset = () => {
    //window.location.replace('login');
    reach_navigate("/login");
    dispatch(confirmNewPasswordFailure());
    dispatch(newPasswordConfirmedStatusFailure());
  }

  const requestNewLink = () => {
    setActiveLoginForm(false);
    setActiveConfirmNewForm(false);
    setActiveResetPasswordForm(true);
  }

  return (
    <Spin
      spinning={loading}
      size="large"
    >
      {confirmNewPasswordStatus === 204 && (
        <Form
          name="normal_login"
          className={`flex flex-col ${css.container}`}
          initialValues={{ remember: true }}
          onFinish={saveNewPassword}
        >
          <Form.Item
            name="password"
            className="mb-3"
            rules={[{ required: true, message: 'Пожалуйста, введите корректный пароль!' }]}
          >
            <Input.Password placeholder="Пароль" />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            className="mb-5"
            rules={[
              {
                required: true,
                message: 'Пожалуйста, подтвердите пароль!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Введенные вами пароли не совпадают!'));
                },
              }),
            ]}
          >
            <Input.Password
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Повторите пароль"
            />
          </Form.Item>
          <Form.Item
            shouldUpdate
            className="mb-0 text-center"
          >
            <div className="flex justify-between">
              <Button
                className="mr-10"
                htmlType="submit"
                type="primary"
              >
                Сохранить
              </Button>
              <Button
                type="default"
                onClick={cancelReset}
              >
                Отменить
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
      {confirmNewPasswordStatus === 400 && (
        <span className={css.confirmPasswordMessage}>
          Ваша ссылка устарела. Запросите новую ссылку на восстановление пароля.
          <span
            className="pl-1 text-blue-frosty-sky underline cursor-pointer"
            onClick={requestNewLink}
          >
            Восстановить пароль
          </span>
        </span>
      )}
    </Spin>
  )}

export default ConfirmNewPasswordForm;
